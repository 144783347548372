"use client";

import classnames from "classnames/bind";
import styles from "./ButtonWrapper.module.css";

interface ButtonWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const cx = classnames.bind(styles);

export const ButtonWrapper = ({ children, className }: ButtonWrapperProps) => {
  return <div className={cx("button-wrapper", className)}>{children}</div>;
};
