"use client";

import classNames from "classnames/bind";
import styles from "./ProductRail.module.css";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { getImageSource } from "../../../helpers/getImageSource";
import { getContentfulImageUrl } from "../../../helpers/images/getContentfulImageUrl";
import { getStoryblokImageUrl } from "../../../helpers/images/getStoryblokImageUrl";
import { TeaserBasic } from "../../../components/TeaserBasic/TeaserBasic";
import type { TeaserBasicProps } from "../../../components/TeaserBasic/TeaserBasic";
import { notNullish } from "../../../helpers/notNullish";
import useEmblaCarousel from "embla-carousel-react";
import { EmblaOptionsType, EmblaCarouselType } from "embla-carousel";
import { useEffect, useRef, useState } from "react";
import { default as EmblaClassNames } from "embla-carousel-class-names";
import { ButtonWrapper } from "../../ButtonWrapper/ButtonWrapper";
import { SliderButton } from "../../slider-button/slider-button";
import { useMediaQuery } from "../../../helpers/useMediaQuery";
import { getSchemeById } from "../../../helpers/colorSchemes";
import { duplicateArrayUntilThresholdLength } from "../../../helpers/duplicateArrayUntilThresholdLength";
import { ProductsRailStoryblok } from "../../../__generated__/storyblok";
import { getFilteredItemsByRedBullVersion } from "../../../helpers/data/getFilteredProductCategories";
import { ProductItem } from "./ProductItemType";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import template from "../../../helpers/template";
import { ButtonPcs } from "../../ButtonPcs/ButtonPcs";
import { useRailFlyOverContext } from "../../../helpers/RailFlyOverContextProvider";
import { useProductRailContext } from "../../../helpers/ProductRailContext";
import { getStoryblokImageDimension } from "../../../helpers/images/getStoryblokImageDimension";

const cx = classNames.bind(styles);

const MINIMUM_VISIBLE_SLIDES = 8; /* Carousel length needs to match number of visible slides in the Rotator */
const DESKTOP_SLIDE_WIDTH = 172; /* Width of each Can. Also used in CSS */
const DESKTOP_SLIDE_WIDTH_SELECTED = 200; /* Width of each Can. Also used in CSS */
// const DESKTOP_SLIDE_SELECTED_SCALE = 172 / 200 - 1; /* 0.1627906977 */

const MOBILE_CAROUSEL_WIDTH = 1000; /* Width of the "visible" Can viewport, is a magic number becuase of rotation effect */
const MOBILE_SLIDE_WIDTH = 144;
const MOBILE_SLIDE_WIDTH_SELECTED = 168;
const MOBILE_SLIDE_SELECTED_SCALE = 168 / 144 - 1; /* 0.1666666667 */

export const ProductRail = ({
  className,
  kicker,
  // title,
  text,
  productItems = [],
  filter,
  animate,
}: {
  className?: string;
  kicker: TeaserBasicProps["kicker"];
  // title: TeaserBasicProps["title"];
  text: TeaserBasicProps["text"];
  productItems?: ProductItem[];
  filter?: ProductsRailStoryblok["kind"];
  animate?: boolean;
}) => {
  const { selectedProductRailItem } = useProductRailContext();

  const translations = useTranslationsContext();

  // const { ref, hasIntersected } = useObserveElementRef<HTMLDivElement>({
  //   threshold: 0.5,
  // });

  const [isAnimated, setIsAnimated] = useState(false);

  const isDesktop = useMediaQuery("(width >= 1000px)", {
    initializeWithValue: false,
  });

  const imageLoading = useImageLoading();

  const emblaContainerRef = useRef<HTMLDivElement | null>(null);

  const EMBLA_OPTIONS: EmblaOptionsType = {
    align: isDesktop ? "start" : "center",
    loop: true,
    skipSnaps: true,
    direction: "ltr",
    watchDrag: true,
    container: emblaContainerRef.current,
  };

  // Embla Carousel
  const [emblaRef, emblaApi] = useEmblaCarousel(EMBLA_OPTIONS, [
    EmblaClassNames({
      dragging: cx("is-dragging"),
    }),
  ]);

  // Embla Next/Prev buttons
  const [prevBtnDisabled, setPrevBtnDisabled] = useState<boolean>(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState<boolean>(true);

  // Embla selected/scroll
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const containerRef = useRef<HTMLDivElement | null>(null);
  const { show: openRailFlyOver } = useRailFlyOverContext();

  const filteredItems = getFilteredItemsByRedBullVersion(productItems, filter);

  // Animate can slide ins
  useEffect(() => {
    if (!animate || !containerRef.current) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.intersectionRatio >= 0.66) {
          setIsAnimated(true);
        }
      },
      { threshold: 0.66 },
    );

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [animate]);

  // Embla does not duplicate items to create the loop effect,
  // therefore, we need to duplicate the items until we have min number.
  // Ff filtering removes all items, return the original list.
  // Total number of slides has to be bigger that min visible slides.
  const duplicatedProductItems = duplicateArrayUntilThresholdLength(
    filteredItems.length !== 0 ? filteredItems : productItems,
    MINIMUM_VISIBLE_SLIDES + 1,
  );

  const colorScheme = getSchemeById(
    duplicatedProductItems[selectedIndex].colorSchemeKey,
  );
  const colorSchemeSecondary = colorScheme.secondary ?? colorScheme.primary;

  const duplicatedProductItemsRef = useRef(duplicatedProductItems);
  const selectedProductRailItemRef = useRef(selectedProductRailItem);

  useEffect(() => {
    duplicatedProductItemsRef.current = duplicatedProductItems;
    selectedProductRailItemRef.current = selectedProductRailItem;
  }, [duplicatedProductItems, selectedProductRailItem]);

  useEffect(() => {
    if (selectedProductRailItemRef.current) {
      selectedProductRailItemRef.current({
        title: duplicatedProductItemsRef.current[selectedIndex].title,
        url: duplicatedProductItemsRef.current[selectedIndex].url,
        productImageUrl:
          duplicatedProductItemsRef.current[selectedIndex].productImageUrl,
        colorSchemeKey:
          duplicatedProductItemsRef.current[selectedIndex].colorSchemeKey,
        productImageAlt:
          duplicatedProductItemsRef.current[selectedIndex].productImageAlt,
        redBullVersion:
          duplicatedProductItemsRef.current[selectedIndex].redBullVersion,
      });
    }
  }, [selectedIndex]);

  // Embla useEffect
  useEffect(() => {
    if (!emblaApi) return;

    // Embla onSelect
    const onSelect = (emblaApi: EmblaCarouselType) => {
      setSelectedIndex(emblaApi.selectedScrollSnap());
      setPrevBtnDisabled(!emblaApi.canScrollPrev());
      setNextBtnDisabled(!emblaApi.canScrollNext());
    };

    const slides = Array.from(
      containerRef.current?.querySelectorAll('div[data-product-rail="card"]') ??
        [],
    ).filter((item) => item instanceof HTMLDivElement);

    const calculateSlidePositions = (emblaApi: EmblaCarouselType) => {
      if (!emblaApi) return;

      const engine = emblaApi.internalEngine();

      // List of LoopPoints from Embla
      const loopPoints = engine.slideLooper.loopPoints;

      // Location of the Embla carousel viewport
      const location = engine.location.get();

      // Width of the conatiner Embla Container
      const containerRect = engine.containerRect.width;

      slides.forEach((slide, index) => {
        // DESKTOP LOGIC START

        /**
         * The negative position where Embla loops the slide
         */
        const desktopLoopPoint = loopPoints ? loopPoints[index]?.loopPoint : 0;

        /**
         * Is the location of the carousel container (eg -2200) past a loop point (eg -2130.5)?
         */
        const desktopLooped = (location ?? 0) < desktopLoopPoint ? true : false;

        /**
         * get the location of the carousel container (eg -2200)
         * divied by slide width (eg 200)
         * plus the index number
         *
         * Cant use `.toFixed(2)`` as it returns a string,
         * so using `Math.round` hack to convert to two decimals.
         */

        /* `toFixed` verion as it's easier to read */
        // const rotation = (
        //   (location ?? 0) / SLIDE_WIDTH +
        //   index
        // ).toFixed(2);

        const desktopInitialRotation =
          (location ?? 0) / DESKTOP_SLIDE_WIDTH + index;

        /**
         * Logic is bonkers!
         *
         * If looped
         * get the location of the carousel container (eg -2200)
         * add the width of the container (eg 670 )
         * minus the value where the slide is looped (eg - 1730.5)
         * divided by slide width (eg 200)
         * else
         * use normal rotation value
         *
         * Cant use `.toFixed(2)` as it returns a string,
         * so using `Math.round` hack to convert to two decimals.
         * (((location ?? 0) + (containerRect ?? 0) - loopPoint) / 200).toFixed(2)
         */

        /* `.toFixed` verion as it's easier to read
         *
         * const loopedRotation = looped
         *   ? (
         *       ((location ?? 0) + (containerRect ?? 0) - loopPoint) /
         *       SLIDE_WIDTH
         *     ).toFixed(2)
         *   : rotation;
         */

        const desktopRotation = desktopLooped
          ? ((location ?? 0) + (containerRect ?? 0) - desktopLoopPoint) /
            DESKTOP_SLIDE_WIDTH
          : desktopInitialRotation;

        const isShrunk = desktopRotation < 0 ? true : false;

        const isBig = desktopRotation < 1 ? true : false;

        // MOBILE LOGIC START

        const productItemsTotal = slides.length;

        const mobileHalfSlideWidth = MOBILE_SLIDE_WIDTH / 2;

        // Get loopPoint of slide, not all slides have a loop point
        const mobileLoopPoint = loopPoints.find((item) => item.index === index);

        // are we past a loop point? Logic changes if we are before/after 0
        const mobileLooped = (() => {
          if (!mobileLoopPoint?.loopPoint) {
            return false;
          }

          if (mobileLoopPoint?.loopPoint > 0) {
            return mobileLoopPoint?.loopPoint
              ? location > mobileLoopPoint?.loopPoint
                ? true
                : false
              : false;
          } else {
            return mobileLoopPoint?.loopPoint
              ? location < mobileLoopPoint?.loopPoint
                ? true
                : false
              : false;
          }
        })();

        // Rotation if carousel isn't looped
        const mobileRotationNormal = (() => {
          if (location > 0) {
            return (
              0 -
              (MOBILE_CAROUSEL_WIDTH / 2 -
                location -
                mobileHalfSlideWidth -
                MOBILE_SLIDE_WIDTH * index) /
                MOBILE_SLIDE_WIDTH
            );
          } else {
            return (
              0 -
              (MOBILE_CAROUSEL_WIDTH / 2 -
                location -
                mobileHalfSlideWidth -
                MOBILE_SLIDE_WIDTH * index) /
                MOBILE_SLIDE_WIDTH
            );
          }
        })();

        // Rotation if the carousel is looped
        const mobileRotationLooped = (() => {
          if (location > 0) {
            return (
              0 -
              (MOBILE_CAROUSEL_WIDTH / 2 -
                location -
                mobileHalfSlideWidth -
                MOBILE_SLIDE_WIDTH * index) /
                MOBILE_SLIDE_WIDTH -
              productItemsTotal
            );
          } else {
            return (
              0 -
              (MOBILE_CAROUSEL_WIDTH / 2 -
                location -
                mobileHalfSlideWidth -
                MOBILE_SLIDE_WIDTH * index) /
                MOBILE_SLIDE_WIDTH +
              productItemsTotal
            );
          }
        })();

        // Rotation for mobile carousel
        const mobileRotation = mobileLooped
          ? mobileRotationLooped
          : mobileRotationNormal;

        // scale up or scale down based on direction
        const scaleDirection =
          mobileRotation > 0
            ? (1 - mobileRotation) * MOBILE_SLIDE_SELECTED_SCALE + 1
            : (1 + mobileRotation) * MOBILE_SLIDE_SELECTED_SCALE + 1;

        // Clamp the selected scale between 1 and 1.x
        const mobileScale = Math.min(
          Math.max(scaleDirection, 1),
          MOBILE_SLIDE_SELECTED_SCALE + 1,
        );

        slide.style.setProperty("--desktop-rotation", String(desktopRotation));
        slide.style.setProperty("--mobile-rotation", String(mobileRotation));
        slide.style.setProperty("--mobile-scale", String(mobileScale));

        // TODO only set this once. Used in init animation, make all number positive.
        slide.style.setProperty(
          "--mobile-start-index",
          String(Math.abs(mobileRotation)),
        );

        slide.setAttribute(
          "data-desktop-past-selected",
          JSON.stringify(isShrunk),
        );
        slide.setAttribute("data-desktop-selected", JSON.stringify(isBig));
        slide.setAttribute(
          "data-desktop-looped",
          JSON.stringify(desktopLooped),
        );
      });
    };

    calculateSlidePositions(emblaApi);
    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
    emblaApi.on("resize", calculateSlidePositions);
    emblaApi.on("reInit", calculateSlidePositions);
    emblaApi.on("scroll", calculateSlidePositions);
  }, [emblaApi]);

  // TODO, setup ARIA and the Title
  // Need confirmation from client on functionality of rail
  return (
    <div
      role="region"
      aria-roledescription="carousel"
      // aria-label={title ?? undefined}
      className={cx("container", className)}
      style={{
        "--product-rail-visible-slides": MINIMUM_VISIBLE_SLIDES,
        "--product-rail-mobile-slide-width": MOBILE_SLIDE_WIDTH,
        "--product-rail-desktop-slide-width": DESKTOP_SLIDE_WIDTH,
        "--product-rail-carousel-width": MOBILE_CAROUSEL_WIDTH,
        "--scheme-background": colorSchemeSecondary,
      }}
      ref={containerRef}
      data-initialized={animate ? String(isAnimated) : undefined}
    >
      <div className={cx("inner")}>
        <div className={cx("content")}>
          <div className={cx("content-inner")}>
            {duplicatedProductItems.map((item, index) => {
              const colorScheme = getSchemeById(item.colorSchemeKey);
              const colorSchemeTextAppearance =
                colorScheme.textAppearance ?? "dark";

              return (
                <div
                  key={index}
                  className={cx("teaser")}
                  data-selected={index === selectedIndex ? true : undefined}
                >
                  <TeaserBasic
                    kicker={kicker}
                    title={item.title}
                    titleClassName={cx("title")}
                    titleSize="x-large"
                    text={text}
                    appearance={colorSchemeTextAppearance}
                    headingElement="title"
                  >
                    <ButtonWrapper className={cx("button-wrapper")}>
                      {/* For A11Y we could also consider have a button per can and hide/show them */}
                      <ButtonPcs
                        className={cx("button")}
                        size="large"
                        href={item.url ?? undefined}
                        customColor={colorScheme.customButtonColor}
                      >
                        {template(
                          translations["productRail.slide.cta"],
                          "See product",
                        )}
                      </ButtonPcs>
                      <ButtonPcs
                        className={cx("button")}
                        size="large"
                        onClick={openRailFlyOver}
                        kind="cover-up"
                      >
                        {template(
                          translations["productRail.slide.see_all"],
                          "Select your flavor",
                        )}
                      </ButtonPcs>
                    </ButtonWrapper>
                  </TeaserBasic>
                </div>
              );
            })}

            {/*
          <TeaserBasic
            kicker={kicker}
            title={title}
            titleSize="x-large"
            text={text}
            appearance="dark"
            headingElement="kicker"
          >
            <ButtonWrapper className={cx("button-wrapper")}>
              {/* For A11Y we could also consider have a button per can and hide/show them 
              <CosmosButton
                className={cx("button")}
                size="large"
                href={duplicatedProductItems?.[selectedIndex].url ?? undefined}
              >
                {/* TODO: Translation 
                See product
              </CosmosButton>
              <CosmosButton
                className={cx("button")}
                size="large"
                kind="cover-up"
                href="href"
              >
                <CosmosIconCan slot="icon" className={cx("button-icon")} />
                {/* TODO: Translation 
                Select your flavor
              </CosmosButton>
            </ButtonWrapper>
          </TeaserBasic>
          */}
          </div>
          <div
            className={cx("slider-button-wrapper")}
            role="group"
            aria-controls={template(
              "carousel.controls.controlsAriaLabel",
              "Slide controls",
            )}
          >
            <SliderButton
              disabled={prevBtnDisabled}
              kind="previous"
              buttonKind="secondary"
              accessibilityLabel={template(
                translations["carousel.controls.previousSlide"],
                "Previous card",
              )}
              className={cx("slider-button", "slider-button--prev")}
              onClick={() => emblaApi?.scrollPrev()}
            />
            <SliderButton
              disabled={nextBtnDisabled}
              kind="next"
              accessibilityLabel={template(
                translations["carousel.controls.nextSlide"],
                "Next card",
              )}
              className={cx("slider-button", "slider-button--next")}
              buttonKind="secondary"
              onClick={() => emblaApi?.scrollNext()}
            />
          </div>
        </div>
        <div className={cx("carousel")}>
          <div className={cx("embla")} dir="ltr">
            <div className={cx("embla__viewport")} ref={emblaRef}>
              <div className={cx("rotator")}>
                {duplicatedProductItems
                  .map((item, index) => {
                    const imageSource = item.productImageUrl
                      ? getImageSource(item.productImageUrl)
                      : null;

                    const colorScheme = getSchemeById(item.colorSchemeKey);
                    const colorCanShadowRGB = colorScheme.canShadowRGB;

                    return (
                      <div
                        key={index}
                        className={cx("rotator-card")}
                        style={{
                          "--selected-index": selectedIndex,
                          "--index": index,
                          "--scheme-can-shadow-rgb": colorCanShadowRGB,
                          // "--desktop-rotation" set in `calculateSlidePositions`
                          // "--mobile-rotation" set in `calculateSlidePositions`
                          // "--mobile-scale" set in `calculateSlidePositions`
                        }}
                        // data-desktop-past-selected set in `calculateSlidePositions`
                        // data-desktop-selected set in `calculateSlidePositions`
                        // data-desktop-looped set in `calculateSlidePositions`
                        data-product-rail="card"
                        role=""
                      >
                        <a href={item.url} className={cx("image-link")}>
                          <h3 className="visually-hidden">{item.title}</h3>
                          <div className={cx("image-wrap")}>
                            <picture>
                              {item.productImageUrl &&
                                (() => {
                                  const imageDimensions =
                                    getStoryblokImageDimension(
                                      item.productImageUrl,
                                    );
                                  const imageProps = {
                                    loading: imageLoading,
                                    className: cx("img"),
                                    alt: item.productImageAlt,
                                    width: imageDimensions?.width,
                                    height: imageDimensions?.height,
                                  };

                                  switch (imageSource) {
                                    case "contentful": {
                                      return (
                                        <>
                                          <source
                                            media="(width >= 1000px)"
                                            width={DESKTOP_SLIDE_WIDTH}
                                            srcSet={[1, 1.5, 2]
                                              .map((multiplier) => {
                                                const src =
                                                  getContentfulImageUrl(
                                                    item.productImageUrl,
                                                    {
                                                      width: Math.ceil(
                                                        DESKTOP_SLIDE_WIDTH_SELECTED *
                                                          multiplier,
                                                      ),
                                                    },
                                                  );
                                                return `${src} ${multiplier}x`;
                                              })
                                              .join(", ")}
                                          />
                                          <img
                                            {...imageProps}
                                            width={MOBILE_SLIDE_WIDTH}
                                            srcSet={[1, 1.5, 2]
                                              .map((multiplier) => {
                                                const src =
                                                  getContentfulImageUrl(
                                                    item.productImageUrl,
                                                    {
                                                      width: Math.ceil(
                                                        MOBILE_SLIDE_WIDTH_SELECTED *
                                                          multiplier,
                                                      ),
                                                    },
                                                  );
                                                return `${src} ${multiplier}x`;
                                              })
                                              .join(", ")}
                                          />
                                        </>
                                      );
                                    }
                                    case "storyblok": {
                                      return (
                                        <>
                                          <source
                                            media="(width >= 1000px)"
                                            width={DESKTOP_SLIDE_WIDTH}
                                            srcSet={[1, 1.5, 2]
                                              .map((multiplier) => {
                                                const src =
                                                  getStoryblokImageUrl(
                                                    item.productImageUrl,
                                                    {
                                                      width: Math.ceil(
                                                        DESKTOP_SLIDE_WIDTH_SELECTED *
                                                          multiplier,
                                                      ),
                                                    },
                                                  );
                                                return `${src} ${multiplier}x`;
                                              })
                                              .join(", ")}
                                          />
                                          <img
                                            {...imageProps}
                                            width={MOBILE_SLIDE_WIDTH}
                                            srcSet={[1, 1.5, 2]
                                              .map((multiplier) => {
                                                const src =
                                                  getStoryblokImageUrl(
                                                    item.productImageUrl,
                                                    {
                                                      width: Math.ceil(
                                                        MOBILE_SLIDE_WIDTH_SELECTED *
                                                          multiplier,
                                                      ),
                                                    },
                                                  );
                                                return `${src} ${multiplier}x`;
                                              })
                                              .join(", ")}
                                          />
                                        </>
                                      );
                                    }
                                    default: {
                                      return (
                                        <img
                                          {...imageProps}
                                          src={item.productImageUrl}
                                        />
                                      );
                                    }
                                  }
                                })()}
                            </picture>
                          </div>
                        </a>
                      </div>
                    );
                  })
                  .filter(notNullish)}
              </div>
              <div
                className={cx("embla__container")}
                ref={emblaContainerRef}
                aria-hidden={true}
              >
                {duplicatedProductItems
                  .map((item, index) => {
                    const imageSource = item.productImageUrl
                      ? getImageSource(item.productImageUrl)
                      : null;

                    // This carousel is visually hidden, but it what the mouse drags
                    return (
                      <div key={index} className={cx("embla__slide")}>
                        <div>
                          {item.productImageUrl &&
                            (() => {
                              const imageDimensions =
                                getStoryblokImageDimension(
                                  item.productImageUrl,
                                );
                              const ratio = isDesktop
                                ? DESKTOP_SLIDE_WIDTH /
                                  (imageDimensions?.width ?? 1)
                                : MOBILE_SLIDE_WIDTH /
                                  (imageDimensions?.width ?? 1);
                              const imageProps = {
                                loading: imageLoading,
                                className: cx("img"),
                                alt: item.productImageAlt,
                                width: isDesktop
                                  ? DESKTOP_SLIDE_WIDTH
                                  : MOBILE_SLIDE_WIDTH,
                                height: imageDimensions
                                  ? Math.round(imageDimensions.height * ratio)
                                  : undefined,
                              };

                              switch (imageSource) {
                                case "contentful": {
                                  return (
                                    <img
                                      {...imageProps}
                                      src={getContentfulImageUrl(
                                        item.productImageUrl,
                                        {
                                          width: Math.ceil(
                                            isDesktop
                                              ? DESKTOP_SLIDE_WIDTH_SELECTED
                                              : MOBILE_SLIDE_WIDTH_SELECTED,
                                          ),
                                        },
                                      )}
                                      srcSet={[1, 1.5, 2]
                                        .map((multiplier) => {
                                          const src = getContentfulImageUrl(
                                            item.productImageUrl,
                                            {
                                              width: Math.ceil(
                                                (isDesktop
                                                  ? DESKTOP_SLIDE_WIDTH_SELECTED
                                                  : MOBILE_SLIDE_WIDTH_SELECTED) *
                                                  multiplier,
                                              ),
                                            },
                                          );
                                          return `${src} ${multiplier}x`;
                                        })
                                        .join(", ")}
                                    />
                                  );
                                }
                                case "storyblok": {
                                  return (
                                    <img
                                      {...imageProps}
                                      src={getStoryblokImageUrl(
                                        item.productImageUrl,
                                        {
                                          width: Math.ceil(
                                            isDesktop
                                              ? DESKTOP_SLIDE_WIDTH_SELECTED
                                              : MOBILE_SLIDE_WIDTH_SELECTED,
                                          ),
                                        },
                                      )}
                                      srcSet={[1, 1.5, 2]
                                        .map((multiplier) => {
                                          const src = getStoryblokImageUrl(
                                            item.productImageUrl,
                                            {
                                              width: Math.ceil(
                                                (isDesktop
                                                  ? DESKTOP_SLIDE_WIDTH_SELECTED
                                                  : MOBILE_SLIDE_WIDTH_SELECTED) *
                                                  multiplier,
                                              ),
                                            },
                                          );
                                          return `${src} ${multiplier}x`;
                                        })
                                        .join(", ")}
                                    />
                                  );
                                }
                                default: {
                                  return (
                                    <img
                                      {...imageProps}
                                      src={item.productImageUrl}
                                    />
                                  );
                                }
                              }
                            })()}
                        </div>
                      </div>
                    );
                  })
                  .filter(notNullish)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
