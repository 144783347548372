export const duplicateArrayUntilThresholdLength = <T = unknown>(
  array: T[],
  minLength: number,
): T[] => {
  if (array.length === 0) {
    throw new Error("Can't duplicate array of length: 0");
  }
  const result: T[] = [...array];

  while (result.length < minLength) {
    result.push(...array);
  }

  return result;
};
