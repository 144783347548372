"use client";

import { createContext, useContext, useState, useEffect } from "react";
import { RailFlyOverModal } from "../components/RailFlyOver/RailFlyOverModal";
import { LaunchedLocale } from "./locales.types";
import { createPortal } from "react-dom";
import { useTranslationsContext } from "./TranslationsContext";
import { FlyOutData } from "./data/getFlyOutData";

const RailFlyOverContext = createContext<{
  localeString: string;
  show: () => void;
  hide: () => void;
  isLoading: boolean;
  matchingLocale?: LaunchedLocale;
}>({
  localeString: "",
  show: () => {},
  hide: () => {},
  isLoading: false,
  matchingLocale: undefined,
});

export const RailFlyOverContextProvider = ({
  matchingLocale,
  children,
  getFlyOutData,
}: {
  matchingLocale?: LaunchedLocale;
  children: React.ReactNode;
  getFlyOutData: (matchingLocale: LaunchedLocale) => Promise<FlyOutData>;
}) => {
  const translations = useTranslationsContext();
  // TODO: potentially refactor to "visible" | "loading" | "mounted" or similar
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [flyOutData, setFlyOutData] = useState<
    (FlyOutData & { title: string }) | null
  >(null);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        if (!matchingLocale) {
          return;
        }

        const data = await getFlyOutData(matchingLocale);
        setFlyOutData({
          ...data,
          title: translations["productRail.flyOver.title"],
        });
      } catch (error) {
        console.error("Error fetching flyout data:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [matchingLocale, translations, getFlyOutData]);

  useEffect(() => {
    if (isVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isVisible]);

  return (
    <RailFlyOverContext.Provider
      value={{
        localeString: matchingLocale?.localeString ?? "",
        show: () => setIsVisible(true),
        hide: () => setIsVisible(false),
        isLoading,
        matchingLocale,
      }}
    >
      {children}
      {isMounted &&
        !isLoading &&
        !!flyOutData &&
        createPortal(
          <RailFlyOverModal
            title={flyOutData.title}
            featuredProducts={flyOutData.featuredProducts}
            categories={flyOutData.categories}
            onClose={() => setIsVisible(false)}
            isOpen={isVisible}
          />,
          document.body,
        )}
    </RailFlyOverContext.Provider>
  );
};

export const useRailFlyOverContext = () => {
  return useContext(RailFlyOverContext);
};
