import {
  ProductEditionStoryblok,
  ProductsRailStoryblok,
  ProductStoryblok,
} from "../../__generated__/storyblok";
import { ProductItem } from "../../components/blocks/ProductRail/ProductItemType";

type RedBullVersion =
  | ProductStoryblok["redBullVersion"]
  | ProductEditionStoryblok["redBullVersion"];

// Filter the product rail to only show certain categories
const getFilteredCategories = (
  category: ProductsRailStoryblok["kind"] | undefined,
): RedBullVersion[] => {
  switch (category) {
    case "classic":
      return ["original", "sugarfree", "zero"];
    case "sugarfree-classic":
      return ["sugarfree", "zero"];
    case "sugarfree-all":
      return ["sugarfree", "zero", "edition-sugarfree"];
    case "editions-all":
      return ["edition", "edition-sugarfree"];
    case "editions":
      return ["edition"];
    case "editions-sugarfree":
      return ["edition-sugarfree"];
    default:
      return [];
  }
};

export function getFilteredItemsByRedBullVersion(
  products: ProductItem[],
  filter?: ProductsRailStoryblok["kind"],
) {
  if (!filter) {
    return products;
  }

  const filteredCategories = getFilteredCategories(filter ?? "classic");

  return products.filter((item) =>
    filteredCategories.includes(item.redBullVersion),
  );
}
