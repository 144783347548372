"use client";

import { CosmosText, CosmosTitle } from "@cosmos/web/react";
import classnames from "classnames/bind";
import styles from "./experience-worb.module.css";
import { responsiveImageHelper } from "../../../helpers/responsiveImageHelper";
import { useImageLoading } from "../../../helpers/ImageLoadingContext";
import { useLocaleStringContext } from "../../../helpers/LocaleStringContext";
import { TeaserBasic } from "../../TeaserBasic/TeaserBasic";
import template from "../../../helpers/template";
import { useTranslationsContext } from "../../../helpers/TranslationsContext";
import { PcsWorbRailContentType } from "../../../helpers/CrepoContentTypes";
import { ExperienceWorbIcon } from "./ExperienceWorbIcon";

const cx = classnames.bind(styles);

export type ExperienceWorbTimelineItem = {
  url: string | null;
  contextualTitle: string | null;
  title: string | null;
  disciplines: string[] | null;
  image: string | null;
  imageTitle: string | null;
  contentType: PcsWorbRailContentType | null;
};

export interface ExperienceWorbProps {
  title: string | null;
  // teaserTitle: string | null;
  // teaserUrl: string | null;
  // teaserText: string | null;
  // teaserBackground: string | null;
  timelineItems: ExperienceWorbTimelineItem[];
}

export const ExperienceWorb = ({
  title,
  // teaserTitle,
  // teaserUrl,
  // teaserText,
  // teaserBackground,
  timelineItems,
}: ExperienceWorbProps) => {
  const translations = useTranslationsContext();
  const localeString = useLocaleStringContext();
  const imageLoading = useImageLoading();

  return (
    <div className={cx("container")}>
      <div className={cx("content")}>
        {title ? (
          <TeaserBasic
            title={title}
            className={cx("worb-title")}
            appearance="light"
            titleSize="large"
            headingTag="h2"
            headingElement="title"
          />
        ) : (
          <TeaserBasic
            title={template(
              translations["worbExperience.title"],
              "Enter the world of Red Bull",
            )}
            className={cx("worb-title")}
            appearance="light"
            titleSize="large"
            headingTag="h2"
            headingElement="title"
          />
        )}

        {/* Removed as per DPSPCS-110 */}
        {/* {teaserTitle && teaserUrl && teaserText && teaserBackground && (
          <Hub
            title={title}
            teaserTitle={teaserTitle}
            teaserUrl={teaserUrl}
            teaserText={teaserText}
            teaserBackground={teaserBackground}
          />
        )} */}
        {/*<div className={cx("tags")}>
          <div className={cx("tag")}>Tag1</div>
        </div>*/}
        <div className={cx("grid")}>
          {timelineItems.map((timelineItem, index) => {
            return (
              <div key={index} className={cx("grid-item")}>
                <div className={cx("item")}>
                  {timelineItem.contentType && (
                    <div className={cx("icon")}>
                      <ExperienceWorbIcon type={timelineItem.contentType} />
                    </div>
                  )}
                  <div className={cx("text")}>
                    <CosmosTitle
                      className={cx("title")}
                      clamp={5}
                      size="xx-small"
                      tag="h3"
                      weight="bold"
                      appearance="light"
                    >
                      <a
                        className={cx("title-link")}
                        href={timelineItem.url ?? ""}
                      >
                        {timelineItem.contextualTitle ?? timelineItem.title}
                      </a>
                    </CosmosTitle>

                    {timelineItem.disciplines &&
                      timelineItem.disciplines?.length > 0 && (
                        <div className={cx("tag")}>
                          <CosmosText
                            className={cx("tag-label")}
                            // clamp={1}
                            kind="normal"
                            size="x-small"
                            tag="div"
                            weight="regular"
                            appearance="light"
                          >
                            {timelineItem.disciplines[0]}
                          </CosmosText>
                        </div>
                      )}
                  </div>

                  {timelineItem.image && (
                    <picture className={cx("background")}>
                      <img
                        loading={imageLoading}
                        className={cx("background__image")}
                        srcSet={responsiveImageHelper.createCrepoSrcSet(
                          timelineItem.image,
                          localeString,
                        )}
                        sizes="(min-width: 1000px) 500px, 400px"
                        alt={timelineItem.imageTitle ?? ""}
                      />
                    </picture>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
