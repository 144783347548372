"use client";

import { createContext, useContext } from "react";
import { ProductItem } from "../components/blocks/ProductRail/ProductItemType";

type ProductRailContextType = {
  selectedProductRailItem?: (value: ProductItem) => void;
};

export const ProductRailContext = createContext<ProductRailContextType>({});

export const useProductRailContext = () => useContext(ProductRailContext);
