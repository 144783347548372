"use client";

import { CosmosTitle, CosmosText } from "@cosmos/web/react";
import classnames from "classnames/bind";
import styles from "./TeaserBasic.module.css";
import { Prose } from "../../components/prose/prose";
import { KickerNew } from "../../components/KickerNew/KickerNew";
import type {
  CosmosTitleTag,
  CosmosTitleSize,
} from "@cosmos/web/lib/types/components/cosmos-title/cosmos-title.d.ts";
import type { CosmosAppearance } from "@cosmos/web/lib/types/models";

/**
 *  TODO these props could be more verbose:
 *  type HeadingProps =
 *   | { kicker: string; title?: string; headingElement: "kicker" }
 *   | { kicker?: string; title: string; headingElement: "title" }
 *   | { kicker: string; title: string; headingElement: "kicker" | "title" };
 *
 */
export interface TeaserBasicProps {
  className?: string;
  kicker?: string | null;
  kickerSize?:
    | "xx-small"
    | "x-small"
    | "small"
    | "medium"
    | "large"
    | "x-large";
  title?: string | null;
  titleHTML?: React.ReactNode;
  titleSize?: CosmosTitleSize; // 'xx-small' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large'
  text?: React.ReactNode | string | null;
  textSize?: string; // TODO
  appearance: CosmosAppearance; // 'dark' | 'light';
  headingTag?: CosmosTitleTag; // 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  headingElement: "title" | "kicker";
  children?: React.ReactNode;
  textClassName?: string;
  kickerClassName?: string;
  titleClassName?: string;
}

export interface BasicWrapperProps {
  className?: string;
  children?: React.ReactNode;
}

const cx = classnames.bind(styles);

/**
 * @param titleHTML - In case we want to line break the title, send a ReactNode that
 * includes <br /> additionally to the title prop. This will overwrite the title prop then.
 * @param headingElement - If you want to switch the order of the kicker and title, set this to "kicker".
 */
export const TeaserBasic = ({
  className,
  kicker,
  kickerSize,
  title,
  titleHTML,
  titleSize = "medium",
  text,
  textSize = "medium",
  appearance = "dark",
  headingTag = "h2",
  headingElement = "title",
  children,
  textClassName,
  kickerClassName,
  titleClassName,
}: TeaserBasicProps) => {
  const hasKicker = kicker && kicker.length > 0;
  const hasTitle = title && title.length > 0;

  return (
    <BasicWrapper className={cx("container", className)}>
      {(hasKicker || hasTitle) && (
        <>
          {headingElement === "title" ? (
            <div className={cx("header")} data-heading="title">
              {hasTitle && (
                <CosmosTitle
                  className={cx("title", titleClassName)}
                  appearance={appearance}
                  size={titleSize}
                  tag={headingTag}
                >
                  {titleHTML || title}
                </CosmosTitle>
              )}
              {hasKicker && (
                <KickerNew size={kickerSize} appearance={appearance} tag="p">
                  {kicker}
                </KickerNew>
              )}
            </div>
          ) : (
            <div className={cx("header")} data-heading="kicker">
              {hasKicker && (
                <KickerNew
                  className={cx(kickerClassName)}
                  appearance={appearance}
                  tag={headingTag}
                >
                  {kicker}
                </KickerNew>
              )}
              {hasTitle && (
                <CosmosTitle
                  className={cx("title", titleClassName)}
                  appearance={appearance}
                  size={titleSize}
                  tag="p"
                >
                  {titleHTML || title}
                </CosmosTitle>
              )}
            </div>
          )}
        </>
      )}
      {text && (
        <div className={cx("content", textClassName)}>
          {typeof text === "string" ? (
            <CosmosText
              appearance={appearance}
              kind="normal"
              size={textSize}
              tag="p"
            >
              {text}
            </CosmosText>
          ) : (
            /* Treat anything else as a ReactNode */
            // TODO: need to update Prose to use cosmos sizes and breakpoints...
            <Prose className={cx("prose")} appearance={appearance}>
              {text}
            </Prose>
          )}
        </div>
      )}
      {children}
    </BasicWrapper>
  );
};

// Container has responsive padding, design often uses this as a blank container
export const BasicWrapper = ({ className, children }: BasicWrapperProps) => {
  return <div className={cx("container", className)}>{children}</div>;
};
