import { useImageLoading } from "../../helpers/ImageLoadingContext";
import { getStoryblokImageUrl } from "../../helpers/images/getStoryblokImageUrl";
import classNames from "classnames/bind";
import styles from "./RailFlyOver.module.css";
import { getImageSource } from "../../helpers/getImageSource";
import { getSchemeById } from "../../helpers/colorSchemes";
import { CosmosButton, CosmosIconClose, CosmosTitle } from "@cosmos/web/react";
import { useMediaQuery } from "../../helpers/useMediaQuery";
import { ProductInfo } from "./RailFlyOver.types";
import { analyticsFilter } from "../../helpers/analytics";
import { FlyOutData } from "../../helpers/data/getFlyOutData";

type Props = {
  title: string;
  featuredProducts: FlyOutData["featuredProducts"];
  categories: FlyOutData["categories"];
  onClose: () => void;
};

const cx = classNames.bind(styles);

export function RailFlyOver({
  title,
  categories,
  featuredProducts,
  onClose,
}: Props) {
  const isDesktop = useMediaQuery("(width >= 650px)", {
    initializeWithValue: false,
  });

  return (
    <div className={cx("container")}>
      <div className={cx("header")}>
        <CosmosTitle appearance="dark" size="medium" className={cx("title")}>
          {title}
        </CosmosTitle>
        <CosmosButton
          onClick={onClose}
          className={cx("close-button")}
          size={isDesktop ? "large" : "small"}
          appearance="dark"
          kind="secondary"
          shape="circle"
          aria-label="Close"
        >
          <CosmosIconClose className={cx("icon-close")} slot="icon" />
        </CosmosButton>
      </div>
      <div className={cx("inner")}>
        <div className={cx("featuredProducts")}>
          {featuredProducts.map((product, index) => (
            <FeaturedProduct
              key={`featuredProduct_${index}`}
              title={product.title}
              graphicUrl={product.graphicUrl}
              graphicAlt={product.graphicAlt}
              productId={product.colorScheme}
              url={product.url}
            />
          ))}
        </div>
        {categories.map((category, index) => (
          <ProductsCategory
            key={`product_category_${index}`}
            title={category.title}
            products={category.products}
            url={category.url}
          />
        ))}
      </div>
    </div>
  );
}

function FeaturedProduct({
  productId,
  graphicUrl,
  graphicAlt,
  url,
  title,
}: {
  title: string;
  productId: string;
  graphicUrl: string;
  graphicAlt?: string;
  url: string;
}) {
  const scheme = getSchemeById(productId);
  const backgroundStyle = {
    "--productBackgroundColor": scheme.secondary ?? scheme.primary,
  };
  const imageSource = graphicUrl ? getImageSource(graphicUrl) : null;

  const imageLoading = useImageLoading();

  return (
    <a
      className={cx("featuredProduct")}
      href={url}
      style={backgroundStyle}
      onClick={() => {
        analyticsFilter({
          value: productId,
        });
      }}
    >
      <div className={cx("featuredProduct__inner")}>
        <CosmosTitle
          className={cx("title")}
          appearance={scheme.textAppearance}
          size="small"
        >
          {title}
        </CosmosTitle>
        {imageSource === "storyblok" ? (
          <img
            className={cx("featuredProduct__image")}
            loading={imageLoading}
            src={getStoryblokImageUrl(graphicUrl, {
              width: 352,
              height: undefined,
            })}
            alt={graphicAlt}
          />
        ) : (
          <img
            style={backgroundStyle}
            className={cx("featuredProduct__image")}
            loading={imageLoading}
            src={graphicUrl}
            alt={graphicAlt}
          />
        )}
      </div>
    </a>
  );
}

function ProductsCategory({
  title,
  url,
  products,
}: {
  title: string;
  url: string;
  products: ProductInfo[];
}) {
  return (
    <div>
      <a
        className={cx("productsCategory")}
        href={url}
        onClick={() => {
          analyticsFilter({ value: title });
        }}
      >
        <CosmosTitle appearance="dark" size="x-small">
          {title}
        </CosmosTitle>
      </a>
      <div className={cx("productsSection")}>
        {products.map((product, index) => (
          <ProductSquare key={index} {...product} />
        ))}
      </div>
    </div>
  );
}

function ProductSquare({
  graphicUrl,
  graphicAlt,
  colorScheme,
  url,
}: {
  colorScheme: string;
  graphicUrl: string;
  graphicAlt?: string;
  url: string;
}) {
  const scheme = getSchemeById(colorScheme);
  const backgroundStyle = {
    "--productBackgroundColor": scheme.secondary ?? scheme.primary,
  };
  const imageSource = graphicUrl ? getImageSource(graphicUrl) : null;

  const imageLoading = useImageLoading();

  return (
    <a
      href={url}
      style={backgroundStyle}
      className={cx("productSquare")}
      onClick={() => analyticsFilter({ value: colorScheme })}
    >
      {imageSource === "storyblok" ? (
        <img
          className={cx("image")}
          loading={imageLoading}
          src={getStoryblokImageUrl(graphicUrl, {
            width: 352,
            height: undefined,
          })}
          alt={graphicAlt}
        />
      ) : (
        <img
          style={backgroundStyle}
          className={cx("image")}
          loading={imageLoading}
          src={graphicUrl}
          alt={graphicAlt}
        />
      )}
    </a>
  );
}
