import { CosmosIconStory, CosmosIconVideo } from "@cosmos/web/react";
import { PcsWorbRailContentType } from "../../../helpers/CrepoContentTypes";

/*
 * This map is a preparation of mapping content types to mostly unique icons.
 * In case the mapping is done mostly in badges, a switch case would be more appropriate.
 */
const iconMap: Record<PcsWorbRailContentType, JSX.Element | null> = {
  AppleImmersiveVideo: null,
  AudioClip: null,
  AudioEpisode: null,
  AudioSeason: null,
  AudioSeries: null,
  AuthorProfile: null,
  ContentCollection: null,
  EditorialList: null,
  EnergyDrink: null,
  EpisodeVideo: null,
  EventDetail: null,
  EventProfile: null,
  EventSeason: null,
  EventSeries: null,
  ExternalContent: null,
  ExternalPerson: null,
  ExternalTeam: null,
  Film: <CosmosIconVideo />,
  Image: null,
  ImageGallery: null,
  Immersive3DModel: null,
  Judge: null,
  LiveVideo: null,
  Location: null,
  Misc: null,
  Occasion: null,
  OccasionCampaign: null,
  OnsitePromotion: null,
  PartnerProfile: null,
  PersonProfile: null,
  Photographer: null,
  Playlist: null,
  PreviewClip: null,
  ProjectProfile: null,
  RallyDriver: null,
  RallyTeam: null,
  RecapVideo: null,
  ResultsAndStandings: null,
  Season: null,
  Show: null,
  Story: <CosmosIconStory />,
  Tag: null,
  TeamProfile: null,
  TrailerVideo: null,
  TvCommercial: null,
  TvPartnerChannel: null,
  Video: null,
  Video360: null,
  Video3D: null,
  VideoChannel: null,
};

export const ExperienceWorbIcon = ({
  type,
}: {
  type: PcsWorbRailContentType;
}) => {
  // This "null" is simply a placeholder in case there'll be a general fallback icon.
  return iconMap[type] ?? null;
};
