import { forwardRef } from "react";
import classNames from "classnames/bind";
import styles from "./KickerNew.module.css";
import type { CosmosAppearance } from "@cosmos/web/lib/types/models";
import { CosmosText, CosmosTextElement } from "@cosmos/web/react";
import { CosmosTextTag } from "@cosmos/web";

export interface KickerProps {
  children: React.ReactNode;
  className?: string;
  tag?: CosmosTextTag; // 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
  appearance?: CosmosAppearance; // 'dark' | 'light';
  // TODO: don't manually define these variants, but `CosmosTextSize` is just string typed
  size?: "xx-small" | "x-small" | "small" | "medium" | "large" | "x-large";
}

const cx = classNames.bind(styles);

export const KickerNew = forwardRef<CosmosTextElement, KickerProps>(
  (
    { children, className, tag = "p", appearance = "dark", size = "small" },
    ref,
  ) => (
    <CosmosText
      ref={ref}
      className={cx("kicker", className)}
      kind="subtle"
      size={size}
      weight="bold"
      appearance={appearance}
      tag={tag}
    >
      {children}
    </CosmosText>
  ),
);
