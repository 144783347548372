import React from "react";
import { RailFlyOver } from "./RailFlyOver";
import styles from "./RailFlyOverModal.module.css";
import classNames from "classnames/bind";
import { FlyOutData } from "../../helpers/data/getFlyOutData";
const cx = classNames.bind(styles);

export interface RailFlyOverModalProps {
  title: string;
  featuredProducts: FlyOutData["featuredProducts"];
  categories: FlyOutData["categories"];
  isOpen?: boolean;
  onClose: () => void;
}

export const RailFlyOverModal = ({
  isOpen = true,
  onClose,
  categories,
  featuredProducts,
  title,
}: RailFlyOverModalProps) => {
  return (
    <div className={cx("modalOverlay")} data-is-open={isOpen}>
      <div className={cx("flyOverContainer")} data-is-open={isOpen}>
        <RailFlyOver
          onClose={onClose}
          categories={categories}
          featuredProducts={featuredProducts}
          title={title}
        />
      </div>
    </div>
  );
};
