import { CosmosTextProps } from "@cosmos/web/react";
import { ButtonCustomColor } from "../components/ButtonPcs/ButtonPcs";

type RGB = `${number} ${number} ${number}`;

/**
 *  1. this is just for internal sorting now, often a flavour is shared between markets
 *  and can be "summer", "white", "coconut".
 *  We rarely get a clear list of all editions and their naming, this just helps us keep track a little bit.
 */
interface ColorSchemeType {
  id: string;
  edition?: string[]; // 1
  primary: string;
  secondary?: string;
  textAppearance: CosmosTextProps["appearance"];
  canShadowRGB?: RGB /* RGB format, no commas */;
  customButtonColor?: ButtonCustomColor;
}

// If something in undefined, lets set a default
const fallbackScheme: ColorSchemeType = {
  id: "fallback",
  edition: [],
  primary: "#282874",
  secondary: undefined,
  textAppearance: "dark",
  canShadowRGB: "0 15 30",
};

const colorSchemes: ColorSchemeType[] = [
  {
    id: "energy-drink-original",
    edition: [],
    primary: "#282874",
    secondary: "#efefef",
    textAppearance: "dark",
    canShadowRGB: "44 44 78",
  },
  {
    id: "energy-drink-sugarfree",
    edition: [],
    primary: "#009EDF",
    secondary: "#0096D4",
    textAppearance: "light",
    canShadowRGB: "0 63 89",
    customButtonColor: "white",
  },
  {
    id: "energy-drink-zero",
    edition: [],
    primary: "#B1D0EE",
    secondary: "#A0C6EA",
    textAppearance: "dark",
    canShadowRGB: "66 104 140",
    customButtonColor: "white",
  },
  {
    id: "edition-acai-berry",
    edition: ["purple"],
    primary: "#732c82",
    secondary: "#6D2A7B",
    textAppearance: "light",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-apricot-strawberry",
    edition: ["apricot", "amber"],
    primary: "#F3911B",
    secondary: "#F28B0E",
    textAppearance: "dark",
    canShadowRGB: "103 58 5",
    customButtonColor: "white",
  },
  {
    id: "edition-blueberry",
    edition: ["blue"],
    primary: "#282874",
    secondary: "#26266E",
    textAppearance: "light",
    canShadowRGB: "0 53 80",
    customButtonColor: "white",
  },
  {
    id: "edition-cactus-fruit",
    edition: ["green"],
    primary: "#2CA44A",
    secondary: "#2A9C46",
    textAppearance: "dark",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-coconut-berry",
    edition: ["white", "coconut"],
    primary: "#0070B8",
    secondary: "#006AAF",
    textAppearance: "light",
    canShadowRGB: "36 57 70",
    customButtonColor: "white",
  },
  {
    id: "edition-curuba-elderflower",
    edition: ["green", "summer"],
    primary: "#78B941",
    secondary: "#72B03E",
    textAppearance: "light",
    canShadowRGB: "48 74 26",
    customButtonColor: "white",
  },
  {
    id: "edition-dragon-fruit",
    edition: ["green"],
    primary: "#2CA44A",
    secondary: "#2A9C46",
    textAppearance: "dark",
    canShadowRGB: "20 74 33",
    customButtonColor: "white",
  },
  {
    id: "edition-fig-apple",
    edition: ["winter"],
    primary: "#037274",
    secondary: "#0f5a5a",
    textAppearance: "light",
    canShadowRGB: "0 53 80",
    customButtonColor: "white",
  },
  {
    id: "edition-forest-fruits",
    edition: ["pink"],
    primary: "#e77bab",
    secondary: "#e77bab", // intentionally the same as primary
    textAppearance: "dark",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-grapefruit-blossom",
    edition: ["spring"],
    primary: "#7D62CE",
    secondary: "#6952AD",
    textAppearance: "light",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-juneberry",
    edition: ["blue", "sea-blue"],
    primary: "#0085C8",
    secondary: "#007EBE",
    textAppearance: "light",
    canShadowRGB: "0 53 80",
    customButtonColor: "white",
  },
  {
    id: "edition-iced-vanilla-berry",
    edition: ["winter"],
    primary: "#53B2C2",
    secondary: "#49ADBE",
    textAppearance: "light",
    canShadowRGB: "29 74 82",
    customButtonColor: "white",
  },
  {
    id: "edition-peach-nectarine",
    edition: ["peach"],
    primary: "#ff00ff",
    secondary: "#00ff0f",
    textAppearance: "dark",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-spiced-pear",
    edition: ["ruby"],
    primary: "#E6301F",
    secondary: "#B50045",
    textAppearance: "light",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
  {
    id: "edition-tropical",
    edition: ["yellow"],
    primary: "#FFCB04",
    secondary: "#F6C300",
    textAppearance: "dark",
    canShadowRGB: "104 82 0",
    customButtonColor: "white",
  },
  {
    id: "edition-watermelon",
    edition: ["red"],
    primary: "#E6301F",
    secondary: "#C53224",
    textAppearance: "light",
    canShadowRGB: "79 20 14",
    customButtonColor: "white",
  },
  {
    id: "edition-white-peach", // white-peach is the flavour
    edition: ["summer"],
    primary: "#E24585",
    secondary: "#E24585",
    textAppearance: "light",
    canShadowRGB: "0 15 30",
    customButtonColor: "white",
  },
];

export function getSchemeById(id?: string): ColorSchemeType {
  const selectedScheme =
    colorSchemes.find((theme) => theme.id === id) || fallbackScheme;

  return {
    ...selectedScheme,
  };
}
